<template>
  <dx-util-popup
    ref="uploadedDataPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="true"
    :position="'center'"
    @shown="uploadedDataPopupShown"
    @hidden="uploadedDataPopupHidden"
  >
    <div>
      <dx-data-grid
        id="prepMaterialInventoryGrid"
        ref="prepMaterialInventoryGridRef"
        :data-source="salesData"
        :height="setHeight"
        :cache-enabled="false"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :hover-state-enabled="true"
        :row-alternation-enabled="false"
        @initialized="onInitialized"
        @content-ready="onContentReady"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <!--region    ::DataGird base components -->
        <template #filterToolbar>
          <div class="d-flex">
            <div class="mr-1">
              <dx-util-select-box v-model="selectedStore" :data-source="stores"
                display-expr="text" value-expr="value"
              />
            </div>
            <div class="mr-1">
              <dx-util-select-box v-model="selectedYear" :data-source="years"
                display-expr="text" value-expr="value"
              />
            </div>
            <div class="mr-1">
              <dx-util-select-box v-model="selectedMonth" :data-source="months"
                display-expr="text" value-expr="value"
              />
            </div>
            <div>
              <label :for="fileInputId">
                <input :id="fileInputId" ref="fileInput" type="file" style="display:none" @change="handleFileUpload">
              </label>
            </div>
          </div>
        </template>
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
      </dx-data-grid>

    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import Papa from 'papaparse'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import amazonReportService from '@/http/requests/reports/amazonReportService.js'
import { Notify } from '@/@robustshell/utils'

export default {
  components: {
  },
  mixins: [GridBase, Filters],
  props: {
    componentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      salesData: [],
      popupTitle: '',
      months: [
        { value: 1, text: 'January' },
        { value: 2, text: 'February' },
        { value: 3, text: 'March' },
        { value: 4, text: 'April' },
        { value: 5, text: 'May' },
        { value: 6, text: 'June' },
        { value: 7, text: 'July' },
        { value: 8, text: 'August' },
        { value: 9, text: 'September' },
        { value: 10, text: 'October' },
        { value: 11, text: 'November' },
        { value: 12, text: 'December' },
      ],
      selectedMonth: '',
      years: this.getLastNYears(3),
      selectedYear: '',
      fileInputId: 'fileInputId',
      accountNo: '',
      file: null,
    }
  },
  computed: {
    uploadedDataPopup() {
      return this.$refs.uploadedDataPopupRef.instance
    },
    setHeight() {
      return window.innerHeight / 1.2
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.getStores()
        this.selectedStore = 0
        this.selectedYear = this.years[0].value
        const date = new Date()
        this.selectedMonth = this.months[date.getMonth()].value
        this.uploadedDataPopup.show()
      },
    },
  },
  methods: {
    getLastNYears(n) {
      const currentYear = new Date().getFullYear()
      const years = []
      for (let i = 0; i < n; i++) {
        years.push({ value: currentYear - i, text: currentYear - i })
      }
      return years
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Select File',
          icon: 'bi bi-upload',
          type: 'default',
          onClick: () => {
            this.upload()
          },
        },
        location: 'before',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Save',
          icon: 'icon icon-feather-save',
          type: 'success',
          onClick: () => {
            this.save()
          },
        },
        location: 'before',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Clear',
          icon: 'icon bi-x-lg',
          type: 'danger',
          onClick: () => {
            this.salesData = []
          },
        },
        location: 'before',
      })
    },
    uploadedDataPopupShown() {
      this.popupTitle = 'Monthly Sales Upload Data Check'
      this.salesData = []
    },
    uploadedDataPopupHidden() {
      this.salesData.length = 0
      this.file = null
    },
    closePopup() {
      this.uploadedDataPopup.hide()
    },
    onMonthValueChanged(e) {
      this.selectedMonth = e.value
    },
    onYearValueChanged(e) {
      this.selectedMonth = e.value
    },
    async save() {
      if (this.salesData?.length === 0) {
        Notify.error('You need to upload a file to store the data')
        return
      }
      if (this.selectedStore === 0 || this.selectedStore === null || this.selectedStore === undefined) {
        Notify.error('You need to select a store to upload a file')
        return
      }
      const payload = {
        storeId: this.selectedStore,
        year: this.selectedYear,
        month: this.selectedMonth,
        items: this.salesData,
      }
      await amazonReportService.createMonthlySalesSummary(payload)
      Notify.success('You have succesfully uploaded the sales data')
      this.$emit('on-sales-added')
      this.closePopup()
    },
    upload() {
      this.$refs.fileInput.click()
    },
    handleFileUpload(event) {
      this.file = event.target.files[0]

      // eslint-disable-next-line no-param-reassign
      event.target.value = ''

      const reader = new FileReader()

      reader.onload = () => {
        const contents = reader.result

        // Split the contents by line breaks
        const lines = contents.split(/\r\n|\n/)

        // Find the index of the row that starts with "date/time"
        const dateTimeIndex = lines.findIndex(line => line.startsWith('"date/time"'))

        // Remove all lines before the "date/time" row
        lines.splice(0, dateTimeIndex)

        // Join the lines back together into a string
        const newContents = lines.join('\n')

        // Set up the PapaParse configuration
        const config = {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: results => {
            const data = results.data

            this.salesData = data.map(row => ({
              date: row['date/time'],
              // sid: row['settlement id'],
              description: row.description,
              type: row.type,
              sku: row.sku,
              qty: row.quantity,
              place: row.marketplace,
              acc: row['account type'],
              fulf: row.fulfillment,
              zip: row['order postal'],
              sales: row['product sales'],
              salesTx: row['product sales tax'],
              shipCred: row['shipping credits'],
              shpiCredTx: row['shipping credits tax'],
              giftCred: row['gift wrap credits'],
              giftCredTx: row['giftwrap credits tax'],
              regFee: row['Regulatory Fee'],
              regFeeTx: row['Tax On Regulatory Fee'],
              promRebates: row['promotional rebates'],
              promRebatesTx: row['promotional rebates tax'],
              marketWitheldTx: row['marketplace withheld tax'],
              sellingFee: row['selling fees'],
              fbaFee: row['fba fees'],
              otherTrxFee: row['other transaction fees'],
              other: row.other,
              total: row.total,
            }))
          },
        }
        Papa.parse(newContents, config)
      }

      reader.readAsText(this.file)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
